/**
 *
 * "Mobile version of the Help page. For viewing help-related details."
 *
 * @file   HelpMobilePage.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { Typography, Stack, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { observeHelpContacts } from 'hooks'
import { defaultRoutes } from 'pages'
import { Header } from 'components'
import { Link, PhoneEnabled } from '@mui/icons-material'
import { secondAccent } from 'mainTheme'

function HelpMobilePage() {
  /**
   * Generates help page to view on mobile sized screen
   *
   * @function
   *
   * @returns {object} - Help page
   */
  const theme = useTheme()
  const contacts = observeHelpContacts()

  const contactsByCategory = contacts.reduce((acc, curr) => {
    if (!acc[curr.category]) acc[curr.category] = []
    acc[curr.category].push(curr)
    return acc
  }, {})

  return (
    <Box sx={{ width: '100%' }}>
      <Header routes={defaultRoutes} />
      <Typography variant="h2">Help & Support</Typography>
      <Stack spacing={4} sx={{ margin: '1em 0' }}>
        {Object.keys(contactsByCategory)?.map((categoryKey, index) => (
          <Stack key={categoryKey} spacing={1}>
            <Typography variant="h4" component="h4">
              {index === 0 ? 'Customer Support' : categoryKey}
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
              Contact Name
            </Typography>
            <Stack>
              {contactsByCategory[categoryKey]?.map((contact) => (
                <>
                  {index === 0 ? (
                    <Stack
                      direction="row"
                      key={contact.name}
                      component="a"
                      href={`mailto:spgau.digital@sandvik.com?subject=DeckMapp Support Request`}
                      sx={{ borderBottom: `1px solid ${theme.palette.text.highlight}` }}>
                      <Box sx={{ padding: '0.5em 1em' }}>
                        <Typography>Digital Support Team</Typography>
                        <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                          <a
                            style={{ color: secondAccent }}
                            href={'mailto:spgau.digital@sandvik.com?subject=DeckMapp Support Request'}>
                            spgau.digital@sandvik.com
                          </a>
                        </Typography>
                      </Box>
                      <Stack justifyContent="center" alignItems="end" sx={{ width: '100%', padding: '0.5em 1em' }}>
                        <a
                          style={{ color: secondAccent }}
                          href="https://srp-australia.atlassian.net/servicedesk/customer/portal/1"
                          target="blank">
                          <Link color="secondary" />
                        </a>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      key={contact.name}
                      component="a"
                      href={`tel:${contact.mobile}`}
                      sx={{ borderBottom: `1px solid ${theme.palette.text.highlight}` }}>
                      <Box sx={{ padding: '0.5em 1em' }}>
                        <Typography>Aftermarket Digital Team</Typography>
                        <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                          <a style={{ color: secondAccent }} href={'tel: +61 8 9494 6777'}>
                            +61 8 9494 6777
                          </a>
                        </Typography>
                      </Box>
                      <Stack justifyContent="center" alignItems="end" sx={{ width: '100%', padding: '0.5em 1em' }}>
                        <PhoneEnabled color="secondary" />
                      </Stack>
                    </Stack>
                  )}
                </>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}

export default HelpMobilePage
